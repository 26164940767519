import M from 'materialize-css';

document.addEventListener('DOMContentLoaded', () => {

  M.AutoInit();
  M.Tabs.init(document.getElementById('state-filters'), {});
  M.Collapsible.init(document.querySelectorAll('.collapsible'), {});
  M.Dropdown.init(document.querySelectorAll('.dropdown-trigger'), {
    alignment: 'left',
    autoTrigger: false,
    constrainWidth: false,
    coverTrigger: false,
    closeOnClick: true,
    hover: false,
    inDuration: 150,
    outDuration: 250
  });

  document.addEventListener('touchstart', (e) => {
    if (e.target.closest('.dropdown-trigger')) {
      e.preventDefault(); // Prevent iOS double-tap issues
    }
  });

  const dropdownItems = document.querySelectorAll('.dropdown-content li a');
  dropdownItems.forEach((item) => {
    item.addEventListener('click', function (e) {
      e.stopPropagation(); // Prevent touch event from propagating to other elements
    });

    item.addEventListener('touchstart', function (e) {
      e.preventDefault(); // Prevent default touch behavior
      window.location.href = this.getAttribute('href'); // Navigate to the link
    });
  });

  setTimeout(() => {
    if (document.body.dataset.flash) {
      M.toast({
        html: document.body.dataset.flash,
        displayLength: 2000,
        classes: 'green white-text',
      });
    }
  }, 1000);
});
